/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/html-has-lang */
/* eslint-disable react/button-has-type */

'use client';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  return (
    <html>
      <body>
        <h2>Something went wrong!</h2>
        <button onClick={() => reset()}>Try again</button>
      </body>
    </html>
  );
}
